import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import { Modal, Button, FormGroup, FormControl } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as PassChanged } from "../../assets/images/change-pass-success.svg";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import PasswordRequirements from "../../components/password-requirement/PasswordRequirements";
import axios from "../../util/axios";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ScannerContext } from "../../components/ScannerContext";

const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&*]).{8,}$/,
      "Password must be at least 8 characters, with at least one uppercase letter, one number, and one special character"
    )
    .required("Password is required"),
  current_password: Yup.string()
    .matches(/^(?!^$)/, "Current Password cannot be empty")
    .required("Current Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match")
    .required("Confirm Password is required"),
});

const ChangePassword = ({ show }) => {
  const { fetchTargets, dropdownItems } = useContext(ScannerContext);
  const navigate = useNavigate();
  const [bottom, setBottom] = useState(false);
  const initialValues = {
    password: "",
    confirm_password: "",
    current_password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const handleScroll = (e) => {
    const target = e.target;
    const isBottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
    setBottom(isBottom);
  };

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Please wait.." });
    } else {
      setSubmitBtn({ disabled: false, label: "Save Changes" });
    }

    // init();
  }, [isLoading]);

  useEffect(() => {
    if (show) {
      setBottom(false);
    }
  }, [show]);

  const handleSubmit = async (values, helpers) => {
    setIsLoading(true);

    try {
      const response = await axios.post("change-password", values);
      const data = response.data;
      helpers.resetForm({
        values: {
          password: "",
          confirm_password: "",
          current_password: "",
        },
      });

      setIsLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (e) {
      setIsLoading(false);
      if (e.response.status === 422) {
        let errorField = e.response.data;
        helpers.setFieldError(errorField?.[0]?.path, errorField?.[0]?.msg);
      }
    }
  };

  const handleCancel = async () => {
    try {
      fetchTargets();
      let defaultTargetId = dropdownItems?.filter(
        (el) => el?.default_scan == 1
      )?.[0];
      navigate(`/assets-inventory/${defaultTargetId?.target_uuid}`);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <div className="main_container_style">
        <div className="contact-us-main">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Logo className="mt-3 d-block mx-auto img-fluid logo-img" />
            {!success && <h2 className="mt-4 text-center ">CHANGE PASSWORD</h2>}
          </div>
          {/* <div className="reset-fragment">
          <div className="reset-main"> */}
          {!success ? (
            <div className="reset-form">
              <div className="reset-input">
                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  render={({
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    values,
                    errors,
                    validateForm,
                    isValid,
                    dirty,
                  }) => (
                    <Form>
                      <Field
                        name="current_password"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup
                            className="mt-3 "
                            controlId="current_password"
                          >
                            <label controlId="floatingCurrentPassword">
                              Current Password
                            </label>
                            <FormControl
                              isValid={initialValues.current_password !== ""}
                              placeholder="Enter a current password "
                              type={showCurrentPassword ? "text" : "password"}
                              {...field}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid password-field"
                                  : ""
                              }
                            />
                            {/* <span
                            onClick={() =>
                              setShowCurrentPassword(!showCurrentPassword)
                            }
                            className="password-eye-position"
                          >
                            {showCurrentPassword ? (
                              <EyeSlashFill />
                            ) : (
                              <EyeFill />
                            )}
                          </span> */}
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                      <hr />
                      <Field
                        name="password"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup className="mt-3 " controlId="password">
                            <label controlId="floatingPassword">
                              New password{" "}
                            </label>
                            <FormControl
                              placeholder="Enter a new password "
                              type={showPassword ? "text" : "password"}
                              {...field}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid password-field"
                                  : ""
                              }
                            />
                            {/* <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="password-eye-position"
                          >
                            {showPassword ? <EyeSlashFill /> : <EyeFill />}
                          </span> */}
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                      <Field
                        name="confirm_password"
                        render={({
                          field,
                          form: { touched, errors },
                          meta,
                        }) => (
                          <FormGroup
                            className="mt-3 "
                            controlId="confirm_password"
                          >
                            <label controlId="floatingConfirmPassword">
                              Confirm password
                            </label>
                            <FormControl
                              placeholder="Confirm Your Password"
                              type={showConfirmPassword ? "text" : "password"}
                              {...field}
                              className={
                                meta.touched && meta.error
                                  ? "is-invalid password-field position-relative"
                                  : ""
                              }
                            />
                            {/* <span
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            className="password-eye-position"
                          >
                            {showConfirmPassword ? (
                              <EyeSlashFill />
                            ) : (
                              <EyeFill />
                            )}
                          </span> */}
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FormGroup>
                        )}
                      />
                      <hr />
                      <div className="mt-2">
                        <PasswordRequirements
                          password={values.password}
                          setPasswordValid={setIsPasswordValid}
                        />
                      </div>
                      <Button
                        type="submit"
                        className={`btn btn-primary scan-button btn-full mx-auto mt-4 w-100`}
                        id="singup-btn"
                        style={{
                          height: 40,
                          padding: 0,
                        }}
                        disabled={submitBtn.disabled || !(isValid && dirty)}
                      >
                        {submitBtn.label}
                      </Button>
                      <div
                        className="text-link mx-auto mt-4 text-center text-4 cursor-pointer"
                        onClick={() => {
                          handleCancel();
                        }}
                      >
                        Cancel
                      </div>
                      {/* <div className="d-flex justify-content-center align-items-center ">
                   
                  </div> */}
                    </Form>
                  )}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="text-center">
                <PassChanged />
              </div>
              <hr className="my-4" />
              <p className="m-0 color-white-70">
                We’ve sent you a confirmation email.
              </p>

              <br />
              <p className="color-white">
                Didn't get the email?{" "}
                <a className="pl-1 text-link">Resend Email</a> or{" "}
                <a className="pl-1 text-link">Contact Support</a>
              </p>
            </>
          )}
          {/* </div>
        </div> */}
          {/* <hr /> */}
          {/* <Modal.Footer>
        <div className="w-100 text-center">
          <Button variant="primary" onClick={onDecline}>
            Close
          </Button>
        </div>
      </Modal.Footer> */}
          <ToastContainer position={toast.POSITION.TOP_CENTER} theme="dark" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
