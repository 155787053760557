import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import OpenIssues from "./OpenIssues";

const Cves = () => {
  const [isActiveLink, setIsActiveLink] = useState("Open Issues");
  const [scanningDetails, setScanningDetails] = useState({
    status: null,
    date: "",
  });

  const updateScanningDetails = (details) => {
    setScanningDetails(details);
  };
  const updateActiveTab = (tab) => {
    setIsActiveLink(tab);
  };  

  return (
    <React.Fragment>
      <div>
        <BreadCrumb
          breadcrumbItems={["Security Issues"]}
          showScanning={true}
          endTime={scanningDetails?.date || new Date()}
          status={scanningDetails?.status}
          isLastUpdate={true}
        />
        <Navbar className="border-bottom mt-4">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-3">
              <Link
                className={`disable-link-color discovery-nav-text text-white ${
                  isActiveLink === "Open Issues" ? "discovery-active-nav-text" : ""
                }`}
                onClick={() => setIsActiveLink("Open Issues")}
              >
                Open Issues
              </Link>
              <Link
                className={`disable-link-color discovery-nav-text text-white mx-4 ${
                  isActiveLink === "History" ? "discovery-active-nav-text" : ""
                }`}
                onClick={() => setIsActiveLink("History")}
              >
                History
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {isActiveLink === "Open Issues" ? (
          <OpenIssues type="open" updateScanningDetails={updateScanningDetails} activeTab={updateActiveTab}/>
        ) : (
          <OpenIssues type="history" updateScanningDetails={updateScanningDetails} activeTab={updateActiveTab}/>
        )}
      </div>
    </React.Fragment>
  );
};

export default Cves;
