import React from "react";
import GenericCard from "../../../../components/card/GenericCard";
import { ReactComponent as Warning } from "../../../../assets/images/sensitive-warning.svg";
import DataInProcess from "../../../../components/empty/NoData";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";

const SensitiveDataCards = ({ data, scanningStatus }) => {
  return (
    <div className="row h-100">
      {data?.length == 0
        ? Array.from({ length: 11 }).map((el) => (
            <div
              className="col-3 sensitive-exposed-empty"
              key={el}
              style={{
                paddingLeft: "7px",
                paddingRight: "7px",
                marginBottom: "14px",
              }}
            >
              <GenericCard
                children={
                  scanningStatus == 0 ||
                  scanningStatus == 1 ||
                  scanningStatus == 2 ? (
                    <DataInProcess />
                  ) : (
                    <NoDataAfterScanning />
                  )
                }
              />
            </div>
          ))
        : data?.map((el) => {
            return (
              <div
                className="col-3"
                key={el.label}
                style={{
                  paddingLeft: "7px",
                  paddingRight: "7px",
                  marginBottom: "14px",
                }}
              >
                <GenericCard
                  children={
                    <div className="flex-col gap-1 flex align-items-center justify-content-between h-100 p-0">
                      <div className="sensitive-icons">
                        <div className="sensitive-icons-sub">
                          {el.icon}
                          {el.value !== 0 && (
                            <div
                              className="warning-icon"
                              style={{
                                position: "absolute",
                                top: "8px",
                                left: "15px",
                              }}
                            >
                              <Warning />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="sensitive-data-card-text">{el.label}</div>
                      <div className="sensitive-data-card-value">
                        {el.value}
                      </div>
                    </div>
                  }
                />
              </div>
            );
          })}
    </div>
  );
};

export default SensitiveDataCards;
