import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: "https://6bf8076ebd5d895e89710fca13432b48@o4508160566820864.ingest.us.sentry.io/4508160577830912",
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error']
    }),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  // tracePropagationTargets: [/^\//, /^https:\/\/*\.cisotronix.com\/api/],
  tracePropagationTargets:[/^\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// export const sentryCreateBrowserRouter =
// Sentry.wrapCreateBrowserRouter(createBrowserRouter);
