import React, { useState } from "react";
import { BulkReject } from "../bulkReject/BulkReject";

const BulkSelection = ({
  actions,
  onActionSelect,
  multiSelect,
  onClearSelectRow,
  multiselectLabel = "Asset",
  totalRecords = null,
  handleSelectionAll,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const toggleBulkDropdown = () => {
    setIsOpenDropdown((prev) => {
      const newState = !prev;
      return newState;
    });
  };

  const closeDropdown = () => {
    setIsOpenDropdown(false);
  };

  const onSelectAction = (action) => {
    closeDropdown();
    onActionSelect(action);
  };

  return (
    <div className="d-flex justify-content-center align-items-center text-white p-3 bulk-selection-container mb-3">
      <div className="table-result d-flex align-items-center">
        <p className="text-nowrap m-0 fw-600 fs-16">
          {multiSelect} {multiselectLabel} Selected
        </p>
        <div className="clear-all-text mx-4" onClick={handleSelectionAll}>
          Select All ({totalRecords})
          
        </div>
        <div className="clear-all-text" onClick={onClearSelectRow}>
          Clear All
        </div>
        <div className="ms-4 clear-devider" style={{ color: "#F5F5F5" }}>
          |
        </div>
        <BulkReject
          actions={actions}
          onSelectAction={onSelectAction}
          isOpen={isOpenDropdown}
          toggleDropdown={toggleBulkDropdown}
        />
      </div>
    </div>
  );
};

export default BulkSelection;
