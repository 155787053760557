import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";

export const BulkReject = ({
  actions,
  isOpen,
  toggleDropdown,
  onSelectAction,
}) => {
  const restoreAction = actions.find((action) => action.name === "Restore");
  return (
    <>
      {restoreAction ? (
        <div className=" mx-3">
          <Button
            className="restore_button "
            onClick={() => onSelectAction(restoreAction)}
          >
            Restore
          </Button>
        </div>
      ) : (
        <Dropdown
          onToggle={toggleDropdown}
          show={isOpen}
          className="border-0 bg-transparent2 w-100 target-dropdown-main mx-2"
          data-bs-theme="dark"
        >
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="target-dropdown d-flex w-100 align-items-center justify-content-between"
            style={{
              height: "40px",
              background: "#454545",
              borderColor: "#398FFE",
              borderRadius: "5px",
              padding: "0px 20px",
              minWidth: "280px",
            }}
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "16px", color: "#A7A7B1" }}
            >
              Select Actions
            </div>
            <ArrowDown
              height={14}
              width={14}
              className={`reject-drop-down-arrow ${isOpen ? "open" : ""}`}
              style={{ marginLeft: "10px" }}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="w-fit mt-2 p-2"
            style={{
              background: "#0A0A0E",
              borderColor: "#398FFE",
              padding: "13px 20px",
              maxHeight: 500,
              minWidth: "290px",
              overflowY: "auto",
            }}
          >
            {actions?.map((action) => (
              <Dropdown.Item
                key={action?.id}
                className="target-hover"
                style={{
                  padding: "8px 12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={() => onSelectAction(action)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {action.name}
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};
