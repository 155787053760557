import React, { useMemo, useState, useEffect } from "react";
import SecurityIssueCard from "../../components/securityissue-card/SecurityIssueCard";
import GroupIssuesTitle from "./GroupIssuesTitle";

const CveList = ({
  isChecked,
  filteredCves,
  collapsedGroups,
  toggleCollapse,
  SetSelectedItems,
  clearSelectedRows,
  handleActionClick,
  actions,
  type,
  handleSelectAll,
  isBulkSelectedAll,
  SetIsBulkSelectedAll,
  selectedCves,
  setSelectedCves,
  checkedState,
  setCheckedState,
  handleSelection,
}) => {
  const [previousIsChecked, setPreviousIsChecked] = useState(isChecked);
  const [isActivePopover, setIsActivePopover] = useState(false);
  const [popoverId, setPopoverId] = useState(null);

  const handleShowPopover = (id) => {
    setPopoverId(id);
    setIsActivePopover(true);
  };
  const handleSelectionWrapper = (selectItem, isSelected, isGroup = false) => {
    const itemId = isGroup ? selectItem.groupInfo : selectItem.target_isssue_id;
    handleSelection(selectItem, isSelected, itemId, isGroup);
  };

  useEffect(() => {
    SetSelectedItems(selectedCves);
  }, [selectedCves, SetSelectedItems]);
  useEffect(() => {
    if (isChecked && !previousIsChecked) {
      setSelectedCves([]);
    }
    setPreviousIsChecked(isChecked);
  }, [isChecked, previousIsChecked]);
  useEffect(() => {
    setSelectedCves([]);
    setCheckedState({});
  }, [clearSelectedRows]);
  useEffect(() => {
    if (isBulkSelectedAll) {
      handleSelectAll();
      SetIsBulkSelectedAll(false);
    }
  }, [isBulkSelectedAll, handleSelectAll, SetIsBulkSelectedAll]);

  useEffect(() => {
    if (isBulkSelectedAll) {
      handleSelectAll();
      SetIsBulkSelectedAll(false);
    }
  }, [isBulkSelectedAll, handleSelectAll, SetIsBulkSelectedAll]);

  const renderItems = useMemo(() => {
    return filteredCves.map((item) => {
      const { id, risk_score } = item;
      const { target_isssue_id } = item;
      const cvssRisk =
        risk_score >= 80 && risk_score <= 100
          ? "primary"
          : risk_score >= 40 && risk_score <= 79
          ? "secondary"
          : "tertiary";
      const isCheckedItem =
        checkedState[item.target_isssue_id || item.issue_name] || false;

      if (isChecked) {
        return (
          <GroupIssuesTitle
            key={item.issue_name}
            group={item}
            collapsedGroups={collapsedGroups}
            toggleCollapse={toggleCollapse}
            cvssRisk={cvssRisk}
            cvssScore={risk_score}
            actions={actions}
            onSelectionChange={(groupInfo, isSelected) =>
              handleSelectionWrapper(groupInfo, isSelected, true)
            }
            clearSelectedRows={clearSelectedRows}
            handleActionClick={handleActionClick}
            type={type}
            isActivePopover={isActivePopover}
            popoverId={popoverId}
            handleShowPopover={handleShowPopover}
            setIsActivePopover={setIsActivePopover}
            isChecked={checkedState[item.issue_name]}
          />
        );
      } else {
        return (
          <>
            <SecurityIssueCard
              key={target_isssue_id}
              classNameVariant={cvssRisk}
              RiskPercentage={risk_score}
              data={item}
              actions={actions}
              onSelectionChange={(data, isSelected) =>
                handleSelectionWrapper(data, isSelected, false)
              }
              clearSelectedRows={clearSelectedRows}
              handleActionClick={handleActionClick}
              type={type}
              isActivePopover={isActivePopover}
              popoverId={popoverId}
              handleShowPopover={handleShowPopover}
              setIsActivePopover={setIsActivePopover}
              criticalityLevel={
                item.ip
                  ? item.asset_criticality_ip
                  : item.asset_criticality_host
              }
              isChecked={isCheckedItem}
            />
          </>
        );
      }
    });
  }, [
    isChecked,
    filteredCves,
    checkedState,
    collapsedGroups,
    toggleCollapse,
    popoverId,
    isActivePopover,
  ]);

  return <>{renderItems}</>;
};

export default CveList;
