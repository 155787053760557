import React, { useEffect, useState, useRef, useContext } from "react";
import BaseFilter from "../../../../components/filter/BaseFilter";
import GenericCard from "../../../../components/card/GenericCard";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import { ThreeCircles } from "react-loader-spinner";
import WebUpdatesHorizontal from "../../../../components/website-updates/WebUpdatesHorizontal";
import { ReactComponent as SortNotSelected } from "../../../../assets/images/SortNotSelected.svg";
import { ReactComponent as SortDown } from "../../../../assets/images/SortDown.svg";
import { ReactComponent as SortUp } from "../../../../assets/images/SortUp.svg";
import DataList from "./DataList";
import SensitiveDataPopup from "../../../../components/sensitive-exposed-data/SensitiveDataPopup";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";
import SensitiveDataCards from "./SensitiveDataCards";
import axios from "../../../../util/axios";
import moment from "moment";
import { useParams, useOutletContext } from "react-router-dom";
import { allCategories } from "../../../../util/sensitive-exposed-categories.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import { isEmpty } from "../../../../util/constants.js";
import { getSensitiveCategories } from "../../../../util/sensitive-exposed-categories.js";

const SensitiveExposedData = () => {
  const fetchIntervalRef = useRef();
  const routeParams = useParams();
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDataInProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortIconHovered, setIsSortIconHovered] = useState(false);
  const [sortDirection, setSortDirection] = useState("");
  const [show, setShow] = useState(false);
  const [statsData, setStatsData] = useState([]);
  const [sensitiveData, setSensitiveData] = useState([]);
  const [drawerDetails, setDrawerDetails] = useState(null);
  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "Domain",
      contentTitle: "Domain",
      data: [],
    },
    { eventKey: "2", title: "URL", contentTitle: "URL", data: [] },
    { eventKey: "3", title: "Title", contentTitle: "Title", data: [] },
    {
      eventKey: "4",
      title: "Description",
      contentTitle: "Description",
      data: [],
    },
  ]);

  const handleSortByCategory = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";

    const sortedData = [...sensitiveData].sort((a, b) => {
      const categoryA = a.name ? a.name.toLowerCase() : "";
      const categoryB = b.name ? b.name.toLowerCase() : "";

      if (categoryA < categoryB) return newDirection === "asc" ? -1 : 1;
      if (categoryA > categoryB) return newDirection === "asc" ? 1 : -1;
      return 0;
    });

    setSensitiveData(sortedData);
    setSortDirection(newDirection);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShowDetails = (info) => {
    setDrawerDetails(info);
    setShow(true);
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const getExportedRows = (dataForExport) => {
    const modifiedData = dataForExport?.reduce((acc, el) => {
      if (el?.data?.length > 0) {
        el.data.forEach((ol) => {
          acc.push({
            category: el.name,
            url: ol.url,
            domain: ol.domain,
            first_detected: ol.first_detected,
            title: ol.title,
            description: ol.description,
            change_status: ol.change_status,
          });
        });
      } else {
        acc.push({
          category: el.name,
        });
      }
      return acc;
    }, []);
    const body = modifiedData.flatMap((data) => {
      return [
        [
          data?.category || "-",
          data?.domain || "-",
          data?.url || "-",
          data?.title || "-",
          data?.description || "-",
          data?.first_detected
            ? moment(data.first_detected).format("DD/MM/YY")
            : "-",
          data?.change_status === 1
            ? "New"
            : data?.change_status === 2
            ? "Changed"
            : data?.change_status === 0
            ? "Unchanged"
            : data?.change_status === 3
            ? "Removed"
            : "-",
        ],
      ];
    });
    return body;
  };

  const fetchSensitiveData = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams?.target_id}/sensitiveData`
      );
      setWebUpdatesData(data?.groups?.changes);
      if (
        data?.data?.length == 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }
      const convertedCategories = {};

      for (const key in data?.countGroup) {
        const newKey = key.toLowerCase().replace(/[^a-z0-9]+/g, "_");
        convertedCategories[newKey] = data?.countGroup[key];
      }
      setWebUpdatesData(data?.groups?.changes);
      if (!isEmpty(convertedCategories)) {
        setStatsData(getSensitiveCategories(convertedCategories)?.sort((a, b) => b.value - a.value));
      }
      if (data?.data?.length > 0) {
        let modifiedData = [];
        allCategories.map((el, index) => {
          let category = {
            id: index + 1,
            name: el,
          };
          let subCategoryData = [];
          let hostnameCount = 0;
          let urlCount = 0;
          data?.data?.map((ol) => {
            if (el == ol.category) {
              if (ol.url) {
                urlCount += 1;
              }
              if (ol?.hostname) {
                hostnameCount += 1;
              }
              subCategoryData.push({
                category: ol.category,
                url: ol.url,
                domain: ol.hostname,
                first_detected: ol.first_detected,
                title: ol.title,
                description: ol.description,
                change_status: ol.change_status,
              });
            }
          });
          category["data"] = subCategoryData;
          category["domains"] = hostnameCount;
          category["urls"] = urlCount;
          modifiedData.push(category);
        });
        setSensitiveData(
          modifiedData.sort((a, b) => b.data?.length - a.data?.length)
        );
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsDataInProcess(true);
    }
  };

  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchSensitiveData();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchSensitiveData();
        fetchIntervalRef.current = setInterval(fetchSensitiveData, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const filteredData = [...sensitiveData];

  return (
    <>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main">
            <div className="col-12 col-lg-6 h-100">
              <div className="row gap-4 top-container-main-row-scanner">
                <div className="col-12 h-75 sensitive-data-cards">
                  <SensitiveDataCards
                    data={statsData}
                    scanningStatus={scanningStatus}
                  />
                </div>
                <div className="col-12 h-25">
                  <div className="row h-100">
                    <div className="col-12">
                      <GenericCard
                        children={
                          <WebUpdatesHorizontal
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDataInProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100">
              <WebScanner title={"Data Leaks Scanner"} />
            </div>
          </div>

          <div className=" pb-5">
            <div className="py-4">
              <BaseFilter
                tabs={tabs}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                className="mb-3"
                totalRecords={filteredData?.length}
                exportHeader={[
                  "Category",
                  "Hostname",
                  "URL",
                  "Title",
                  "Description",
                  "First Detected",
                  "Current State",
                ]}
                exportTitle={`SensitiveExposedData_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={getExportedRows(filteredData)}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <div
                className="sensitive-exposed-data-sort-container d-flex align-items-center"
                onMouseEnter={() => setIsSortIconHovered(true)}
                onClick={handleSortByCategory}
                onMouseLeave={() => setIsSortIconHovered(false)}
              >
                Category
                {isSortIconHovered || sortDirection ? (
                  sortDirection === "desc" ? (
                    <SortDown />
                  ) : sortDirection === "asc" ? (
                    <SortUp />
                  ) : (
                    <SortNotSelected />
                  )
                ) : null}
              </div>
              {isDataInProcess ? (
                <div className="data-in-process">Data in Process </div>
              ) : (
                <div>
                  {filteredData.length > 0 ? (
                    <DataList
                      data={filteredData}
                      openDetails={handleShowDetails}
                    />
                  ) : (
                    <div className="data-in-process">
                      {isDataisPartiallyProcessing
                        ? "Data in Process"
                        : "No Data Was Found"}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="sensitive-exposed-data-sidebar">
        <SensitiveDataPopup
          data={drawerDetails}
          show={show}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default SensitiveExposedData;
